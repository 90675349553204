
const URL = "https://nikicoin.store/";
const URL1 = "https://nikicoin.store/";
// const URL1 = "https://2f5a-79-132-3-74.ngrok-free.app/";


let USER_ID = "5928954497";
// let USER_ID = null

if (window.Telegram && window.Telegram.WebApp) {
  window.Telegram.WebApp.ready();

  if (
    window.Telegram.WebApp.initDataUnsafe &&
    window.Telegram.WebApp.initDataUnsafe.user
  ) {
    USER_ID = window.Telegram.WebApp.initDataUnsafe.user.id;
  } else {
    console.error("Telegram user data is not available.");
  }
} else {
  console.error("Telegram WebApp is not initialized.");
}

const fetchData1 = async (request) => {
  try {
    const response = await fetch(`${URL1 + request}`, {
      method: "get",
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log("data:", request, data);
    return data;
  } catch (error) {
    return null;
  
  }
};

const fetchData = async (request) => {
  try {
    const response = await fetch(`${URL + request}`, {
      method: "get",
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log("data:", request, data);
    return data;
  } catch (error) {
    return null;
  }
};

const sendData = async (request, data) => {
  try {
    const formattedRequest = request.endsWith("/") ? request : `${request}/`;
    const formData = new URLSearchParams(data).toString();
    const response = await fetch(`${URL}${formattedRequest}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData,
    });
    const result = await response.json();
    console.log("post:", request, result);
    return result;
  } catch (error) {
    console.error("Error fetching:", error);
    return null;
  }
};

export const sendCoinsAndEnergy = async (coins, energy) => {
  const response = await sendData("update_coins_and_energy", {
    user_id: USER_ID,
    coins_count: coins,
    energy_count: energy,
  });
  return response;
};

export const sendUpgrade = async (upgradeId) => {
  const response = await sendData("buy_upgrade", {
    user_id: USER_ID,
    upgrade: upgradeId,
  });
  return response;
};

export const sendSpecial = async (specialId) => {
  const response = await sendData("use_boost", {
    user_id: USER_ID,
    boost: specialId,
  });
  return response;
};

export const sendJoinTeam = async (teamId) => {
  const response = await sendData("join_team", {
    user_id: USER_ID,
    team_id: teamId,
  });
  return response;
};

export const sendLeaveTeam = async (teamId) => {
  const response = await sendData("leave_team", {
    user_id: USER_ID,
    team_id: teamId,
  });
  return response;
};

export const sendGame = async () => {
  const response = await sendData("send_games_message", { user_id: USER_ID });
  return response;
};

export const buyPet = async (pet_id, name) => {
  const response = await sendData("buy_pet", {
    user_id: USER_ID,
    pet_id: pet_id,
    name: name,
  });
  return response;
};

export const feedPet = async () => {
  const response = await sendData("feed_pet", { user_id: USER_ID });
  return response;
};

export const buyFood = async (food_id) => {
  const response = await sendData("buy_food", {
    user_id: USER_ID,
    food_id: food_id,
  });
  return response;
};

export const manageMeme = async (meme_id) => {
  const response = await sendData("manage_meme", {
    user_id: USER_ID,
    meme_id: meme_id,
  });
  return response;
};

export const manageTaskMeme = async (meme_id) => {
  const response = await sendData("manage_taskmemes", {
    user_id: USER_ID,
    task_meme_id: meme_id,
  });
  return response;
};

export const manageExtraIncome = async (extra_income_id) => {
  const response = await sendData("manage_extra_income", {
    user_id: USER_ID,
    extra_income_id: extra_income_id,
  });
  return response;
};

export const selectFood = async (food_id) => {
  const response = await sendData("select_food", {
    user_id: USER_ID,
    food_id: food_id,
  });
  return response;
};

export const checkLocation = async (player_ip) => {
  const response = await sendData("check_location", {
    user_id: USER_ID,
    player_ip: player_ip,
  });
  return response;
};
export const SendInvite = async () => {
  const response = await sendData("send_invite_message", { user_id: USER_ID });
  return response;
};
export const completeTask = async (task_id) => {
  const response = await sendData("complete_task", { task_id: task_id });
  return response;
};
export const GeneratePaymentLink = async (amount, product) => {
  const response = await sendData("generate_payment_link", {
    user_id: USER_ID,
    amount: amount,
    product: product,
  });
  return response;
};

export const seeTask = async (task_id) => {
  const response = await sendData("see_task", { task_id: task_id });
  return response;
};

export const seeTaskMeme = async (task_id) => {
  const response = await sendData("see_taskmeme", {
    taskmeme_id: task_id,
    user_id: USER_ID,
  });
  return response;
};

export const seePromotion = async (promotion_id) => {
  const response = await sendData("see_promotion", {
    promotion_id: promotion_id,
  });
  return response;
};

export const completePromotion = async (promotion_id) => {
  const response = await sendData("complete_promotion", {
    promotion_id: promotion_id,
  });
  return response;
};

// 1234567890
export const activatePanameraGiveawayFriendTask = async () => {
  const response = await sendData("activate_panamera_giveaway_friend_task", {user_id: USER_ID,});
  return response;
};
export const activatePanameraGiveawayStoryTask = async () => {
  const response = await sendData("activate_panamera_giveaway_story_task", {user_id: USER_ID,});
  return response;
};
export const getUserInfo = async () => {
  const response = await fetchData("initialize_user" + "?user_id=" + USER_ID);
  return response;
};
export const getPanameraGiveawayTasks = async () => {
  const response = await fetchData("get_panamera_giveaway_tasks" + "?user_id=" + USER_ID);
  return response;
};
export const getPanameraGiveawayUsers = async () => {
  const response = await fetchData("get_panamera_giveaway_users");
  return response;
};
export const getNftCountNumber = async () => {
  const response = await fetchData("nft_count_number");
  return response;
};

export const getPumpTasks = async () => {
  const response = await fetchData("get_pump_tasks" + "?user_id=" + USER_ID);
  return response;
};
export const getUserUpgrades = async () => {
  const response = await fetchData("get_user_upgrades" + "?user_id=" + USER_ID);
  return response;
};
export const getPotentialUsdtSection = async (wallet_address) => {
  const response = await fetchData("get_potential_usdt_section" + "?user_id=" + USER_ID +"&wallet_address="+wallet_address);
  return response;
};
export const getPumpTaskInfo = async (language,task_id) => {
  const response = await fetchData("get_pump_task_info" + "?language=" + language + "&pump_task_id="+task_id);
  return response;
};

export const completePumpTask = async (pump_task_id,answer_id) => {
  const response = await sendData("complete_pump_task", {
    pump_task_id: pump_task_id,
    user_id: USER_ID,
    answer_id:answer_id
  });
  return response;
};

export const getUserPromotions = async () => {
  const response = await fetchData(
    "get_user_promotions" + "?user_id=" + USER_ID
  );
  return response;
};
export const getUserExtraIncome = async () => {
  const response = await fetchData(
    "get_player_extraincomes" + "?user_id=" + USER_ID
  );
  return response;
};

export const getPlayerTaskMemes = async () => {
  const response = await fetchData(
    "get_player_taskmemes" + "?user_id=" + USER_ID
  );
  return response;
};

export const getUserSpecials = async () => {
  const response = await fetchData("get_user_boosts" + "?user_id=" + USER_ID);
  return response;
};

export const getUserTasks = async () => {
  const response = await fetchData("get_user_tasks" + "?user_id=" + USER_ID);
  return response;
};

export const getUserRefferedCount = async () => {
  const response = await fetchData(
    "friends_reffered_count" + "?user_id=" + USER_ID
  );
  return response;
};

export const getUserFriends = async () => {
  const response = await fetchData("friends_list" + "?user_id=" + USER_ID);
  return response;
};

export const getSearchTeams = async (searchQuery) => {
  const response = await fetchData(`get_teams?search_query=${searchQuery}`);
  return response;
};

export const getTeam = async (team_id) => {
  const response = await fetchData(`get_team?team_id=${team_id}`);
  return response;
};

export const getTopTeams = async () => {
  const response = await fetchData(`get_top5_teams`);
  return response;
};

export const getPlayerPet = async () => {
  const response = await fetchData(
    "get_player_pet" + "?user_id=" + USER_ID
  );
  return response;
};
export const getAllPets = async () => {
  const response = await fetchData("get_all_pets");
  return response;
};
export const getPet = async (petId) => {
  const response = await fetchData("get_pet" + "?pet_id=" + petId);
  return response;
};
export const getPlayerFood = async () => {
  const response = await fetchData("get_player_food" + "?user_id=" + USER_ID);
  return response;
};
export const getPlayerPromotions = async () => {
  const response = await fetchData(
    "get_player_promotions" + "?user_id=" + USER_ID
  );
  return response;
};
export const getPlayerMemes = async () => {
  const response = await fetchData("get_player_memes" + "?user_id=" + USER_ID);
  return response;
};
export const getPlayerLocationtasks = async () => {
  const response = await fetchData(
    "get_player_locationtasks" + "?user_id=" + USER_ID
  );
  return response;
};

export const getAllLeagues = async () => {
  const response = await fetchData("get_all_leagues");
  return response;
};
export const getLeague = async (time_period, league) => {
  const response = await fetchData(
    "get_league" + "?time_period=" + time_period + "&league=" + league
  );
  return response;
};
export const getFriendsList = async () => {
  const response = await fetchData("friends_list" + "?user_id=" + USER_ID);
  return response;
};

export const getUserDaylys = async () => {
  const response = await fetchData1("get_user_daily_tasks" + "?user_id=" + USER_ID);  
  return response;
};

export const claimDailyRewords = async () => {
  const response = await sendData("complete_daily_task", {user_id: USER_ID});  
  return response;
};


export const getQuizDetail = async (quizId, locale) => {
  const response = await fetchData(
    "get_quiz_detail" +
      "?quiz_id=" +
      quizId +
      "&player_id=" +
      USER_ID +
      "&locale=" +
      locale
  );
  return response;
};

export const getQuizMapsAndQuizzes = async (petId) => {
  const response = await fetchData(
    "get_quiz_maps_and_quizzes" + "?pet_id=" + petId + "&player_id=" + USER_ID
  );
  return response;
};

export const completeQuiz = async (question_id, pet_id, answer_id,quiz_id) => {
  const response = await sendData("complete_quiz", {
    player_id: USER_ID,
    question_id: question_id,
    pet_id:pet_id,
    answer_id:answer_id,
    quiz_id:quiz_id,
  });
  return response;
};
