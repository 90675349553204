import React, { useState, useEffect } from "react";
import "./PanameraDrop.scss";
import panamera from "./panamera.png";
import people from "./people.png";
import phone from "./phone.png";
import panamera_small from "./panamera_small.png";
import ok from "./ok.png";
import sun from "./sun.png";
import pointer from "./pointer.png";
import done from "./done.png";
import { getPanameraGiveawayTasks, activatePanameraGiveawayStoryTask, activatePanameraGiveawayFriendTask } from '../../http';
import { useAppData } from "../../AppContext";
import Back from '../Back';
import { SendInvite } from "../../http";
import loading from "./loading.png";
import { openLink } from "../../Utils/telegramUtils";
import { useTranslation } from 'react-i18next';

const Popup = ({ isVisible, onClose, taskStatuses,title }) => {
  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h3>{title}</h3>
        <div style={{ marginLeft: "42%" }}>
          {taskStatuses.map((task) => (
            <div className="task" key={task.id}>
              <div className="task_icon">
                <img src={task.icon} alt={`Icon ${task.id}`} />
              </div>
              <div className="task-text">{task.action_text}</div>
              <div className="task-status">
                {/* {task.status === "done" ? (
                  <img src={done} alt="Check Icon" />
                ) : task.status === "pending" ? (
                  <img src={loading} alt="Loading Icon" />
                ) : (
                  <img src={done} alt="Check Icon" />
)} */}
              </div>
            </div>
          ))}
        </div>

        <button className="ok-button" onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
};

function TableWithButton({ data, onShareStory }) {
  const { t } = useTranslation();

  const handleButtonClick = async (item) => {
    if (item.name === t("shareStory")) {
      await activatePanameraGiveawayStoryTask();
      onShareStory();
    } else if (item.name === t("inviteFriend")) {
      await activatePanameraGiveawayFriendTask();
      await SendInvite();
      window.Telegram.WebApp.close();
    }
  };

  const getButtonClass = (status) => {
    switch (status) {
      case 'available':
        return 'promo-button active';
      case 'done':
        return 'promo-button done';
      case 'pending':
        return 'promo-button pending';
      default:
        return 'promo-button';
    }
  };

  const getButtonIcon = (status) => {
    switch (status) {
      case 'available':
        return pointer;
      case 'done':
        return done;
      case 'pending':
        return loading;
      default:
        return pointer;
    }
  };

  return (
    <>
      {data.map((item) => (
        <div className="info_container" key={item.id}>
          <div style={{ width: "3%" }}>{item.id}</div>
          <div className="image_container">
            <img src={item.icon} alt={item.text} />
          </div>
          <div className="des_panamera">{item.text}</div>
          <div className="btn_panamera">
            <div className={getButtonClass(item.status)} onClick={() => handleButtonClick(item)}>
              {item.action_text}
              <img className="arrow-icon" src={getButtonIcon(item.status)} alt="Иконка стрелки" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

const PanameraDrop = () => {
  const { t ,i18n} = useTranslation();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [friendsStatus, setFriendsStatus] = useState("");
  const [storyStatus, setStoryStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPanameraGiveawayTasks();
      setFriendsStatus(data.friend_task_status);
      setStoryStatus(data.story_task_status);
    };

    fetchData();
  }, []);

  const data = [
    { id: 1, text: t("inviteFriendText"),  name: t("inviteFriend"), action_text: friendsStatus === "done" ? t("done") : friendsStatus === "pending" ? t("pending") : t("available"), icon: people, status: friendsStatus },
    { id: 2, text: t("shareStoryText"), name: t("shareStory"), action_text: storyStatus === "done" ? t("done") : storyStatus === "pending" ? t("pending") : t("Create"), icon: phone, status: storyStatus },
    { id: 3, text: t("participateInContestText"), name: t("participateInContest"), action_text: t("participateInContest"), icon: panamera_small, status: 'available' },
  ];


  const data1 = [
    { id: 1, name: t("inviteFriend"), action_text: friendsStatus === "done" ? t("done") : friendsStatus === "pending" ? t("pending") : t("available"), icon: people, status: friendsStatus },
    { id: 2, name: t("shareStory"), action_text: storyStatus === "done" ? t("done") : storyStatus === "pending" ? t("pending") : t("available"), icon: phone, status: storyStatus },
  ];

  const handleShareStory = () => {
    const mediaUrl = (i18n.language === 'ru' || i18n.language === 'uk')
    ? "https://nikicoin.store/media/images/14141.png"
    : "https://nikicoin.store/media/images/212121.png";

    try {
      window.Telegram.WebApp.shareToStory(mediaUrl, {
        backgroundType: "image",
        mediaType: "image",
        text: t("story"),
      });
    } catch (error) {
      console.error("Error sharing story:", error);
    }
  };

  const { dropUsersCount } = useAppData();

  
  return (
    <div className="outer-container_panamera">
      <div className="container_glow_panamera">
        <Back />
        <div className="title_panamera">{t("activateDrop")}</div>

        <img src={panamera} style={{ width: "100%" }} alt="Panamera" />

        <TableWithButton data={data} onShareStory={() => handleShareStory()} />

        <button className="gradient-button">
          <img className="icon_sun" src={sun} alt="Sun" />
          <span className="button-text">{t("buttonText")}</span>
          <img className="button-icon" src={ok} alt="Arrow Icon" />
        </button>

        <button
          className="custom-button"
          onClick={() => {
            if (storyStatus !== "done" || friendsStatus !== "done") {
              setPopupVisible(true);
            } else {
              openLink("https://t.me/nikicompany_support");
            }
          }}
        >
          <span className="button-label">{t("sendForReview")}</span>
          <div className="button-count">
            <img className="button-icon" src={people} alt="People" />
            <span>{dropUsersCount}</span>
          </div>
        </button>

        <div>{t("raffleDate")}</div>
      </div>

      <Popup isVisible={isPopupVisible} taskStatuses={data1} onClose={() => setPopupVisible(false)} title={t("completeTask")} />
    </div>
  );
};

export default PanameraDrop;
